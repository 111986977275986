import React from "react"
import PropTypes from "prop-types"

const SmartTable = props => {
  const { children, data, className } = props

  const table = children || data

  return (
    <div className="overflow-x-auto">
      <table className={`${className ? ` ${className}` : ""} brand-table`}>
        <thead>
          <tr>
            {table &&
              table?.thead &&
              table.thead.map((node, index) => {
                const header = node
                if (header.value) {
                  return (
                    <th key={header._uid} scope="col" className="px-6 py-4">
                      {header.value && header.value}
                    </th>
                  )
                }
                return null
              })}
          </tr>
        </thead>
        <tbody>
          {table?.tbody.map((node, index) => {
            const row = node
            return (
              <tr key={row._uid}>
                {node.body.map((node, index) => {
                  const td = node
                  return (
                    <td
                      key={td._uid}
                      className="max-w-[200px] md:max-w-[400px] px-6 py-4"
                    >
                      {td.value && td.value}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default SmartTable

// ====

SmartTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  responsive: PropTypes.bool,
}

SmartTable.defaultProps = {
  data: null,
  className: "",
  responsive: true,
}
