import React, { useState } from "react"
import StoryblokClient from "storyblok-js-client"
import Layout from "../components/structural/layout"

import * as newsPostStyles from "../templates/news/newsPost.module.css"
import * as legalStyles from "../templates/legal/legal.module.css"

import { storyblokEditable, StoryblokComponent } from "gatsby-source-storyblok"

import { format } from "date-fns"
import HorizontalRule from "../components/instances/horizontalRule"
import OTLink from "../components/global/otLink"
import SectionHero from "../components/sections/sectionHero"
import SmartText from "../utils/TextHandler"
import SmartTable from "../utils/TableHandler"

const SBEditor = () => {
  const [storyblokData, setStoryblokData] = useState(null)
  const [templateData, setTemplateData] = useState("loading")

  function loadBridge(callback) {
    if (typeof window !== "undefined") {
      const existingScript = document.getElementById("storyblokBridge")
      if (!existingScript) {
        const script = document.createElement("script")
        script.src = "//app.storyblok.com/f/storyblok-v2-latest.js"
        script.id = "storyblokBridge"
        document.body.appendChild(script)
        script.onload = () => {
          callback()
        }
      } else {
        callback()
      }
    }
  }

  loadBridge(() => {
    if (typeof window !== "undefined") {
      const { StoryblokBridge, location } = window

      const storyblokInstance = new StoryblokBridge({
        preventClicks: true,
        resolveNestedRelations: true,
        resolveLinks: ["story", "link"],
        resolveRelations: [
          "instance_game.link",
          "instance_press_card.link",
          "section_game_panel_feature.game",
          "section_game_details.game",
          "url",
          "instance_navigation.link",
        ],
      })

      const Storyblok = new StoryblokClient({
        accessToken: process.env.GATSBY_SB_PREVIEW_API_KEY,
        cache: {
          clear: "auto",
          type: "memory",
        },
        resolveNestedRelations: true,
        resolveLinks: ["story", "link"],
        resolveRelations: [
          "instance_game.link",
          "instance_press_card.link",
          "section_game_panel_feature.game",
          "section_game_details.game",
          "url",
          "instance_navigation.link",
        ],
      })

      storyblokInstance.pingEditor(() => {
        if (storyblokInstance.isInEditor()) {
          //  load the draft version
          //        alert("In the editor")
        } else {
          // load the published version
        }
      })

      storyblokInstance.on("enterEditmode", event => {
        // Read the URL of the loaded page to get the template name
        const urlParams = new URLSearchParams(window.location.search)
        const myParam = urlParams.get("_storyblok_c")

        setTemplateData(myParam)

        // loading the draft version on initial enter of editor
        Storyblok.get(`cdn/stories/${event.storyId}`, {
          token: process.env.GATSBY_SB_PREVIEW_API_KEY,
          version: "draft",
          resolve_assets: "1",
          resolve_links: "story,link",
          resolve_relations:
            "instance_game.link,instance_press_card.link,section_game_panel_feature.game,section_game_details.game,url,instance_navigation.link",
        })
          .then(({ data }) => {
            // console.log(data)
            //          if (typeof data.story.content === "string")
            //            data.story.content = JSON.parse(data.story.content)
            setStoryblokData(data.story)
          })
          .catch(error => {
            console.log(error)
          })
      })

      storyblokInstance.on("input", event => {
        // Access currently changed but not yet saved content via:
        //   console.log(event.story.content)
        setStoryblokData(event.story)

        //    alert(JSON.stringify(event.story.content))
      })

      storyblokInstance.on(["published", "change"], event => {
        if (!event.slugChanged) {
          location.reload(true)
        }
      })
    }
  })

  if (storyblokData === null) return <p className="mt-16">Loading...</p>

  console.log("storyblokData", storyblokData)

  if (templateData === "legal") {
    const { content } = storyblokData

    if (!content) return null
    const { content: sections, title, label } = content

    return (
      <div {...storyblokEditable(content)} className="editor-only-styles">
        <Layout isEditorMode={true}>
          <div className="">
            <SectionHero
              blok={{
                title,
              }}
            />

            <div className="py-16 px-4 md:px-8 overflow-scroll">
              {/* {text_content && (
              <section className="px-4">
                <SmartText>{text_content}</SmartText>
              </section>
            )} */}
              <p className="container mx-auto se-body-mobile md:se-body-desktop">
                {label}
              </p>

              {sections &&
                sections.map(section => {
                  if (section.component === "copy") {
                    return (
                      <section
                        key={section._uid}
                        className="container mx-auto mb-8"
                      >
                        <SmartText containerClassName={legalStyles.legalCopy}>
                          {section.copy}
                        </SmartText>
                      </section>
                    )
                  } else if (section.component === "heading") {
                    return (
                      <section
                        id={section.id}
                        key={section._uid}
                        className="container mx-auto mb-4"
                      >
                        <h2>{section.title}</h2>
                      </section>
                    )
                  } else if (section.component === "table") {
                    return (
                      <section
                        key={section._uid}
                        className="container mx-auto mb-8 overflow-hidden"
                      >
                        <SmartTable
                          containerClassName={legalStyles.legalCopy}
                          data={{ ...section.table }}
                        />
                      </section>
                    )
                  }
                  return null
                })}
            </div>
          </div>
        </Layout>
      </div>
    )
  }

  if (templateData === "news-article") {
    const { content } = storyblokData

    if (!content) return null
    const {
      date,
      author,
      title,
      text_content,
      desktop_article_image,
      mobile_article_image,
    } = content

    // If we end up adding more page types extract this to a separate component to keep bloat down in this file
    return (
      <div {...storyblokEditable(content)} className="editor-only-styles">
        <Layout isEditorMode={true}>
          <div>
            <SectionHero
              blok={{
                desktop_image: desktop_article_image,
                mobile_image: mobile_article_image,
              }}
            />
            <section className="py-16">
              <div className="container max-w-[1024px] mx-auto px-4 md:px-8 text-center">
                {title && <h1 className="mb-12">{title}</h1>}
                <div className="mb-12">
                  <HorizontalRule />
                </div>
                {date && (
                  <p className="se-smallprint uppercase text-se-grey mb-0">
                    Posted on {format(new Date(date), "d MMMM, yyyy")}
                    {author && ` by ${author}`}
                  </p>
                )}
              </div>
            </section>

            {text_content && (
              <section className="pb-16">
                <div className="container max-w-[1024px] mx-auto px-4 md:px-8">
                  <SmartText
                    containerClassName={`se-body-regular ${newsPostStyles.newspostText}`}
                  >
                    {text_content}
                  </SmartText>
                </div>
              </section>
            )}

            <section className="py-16 bg-se-black text-se-white">
              <div className="container max-w-[1024px] mx-auto px-4 md:px-8 text-center">
                <OTLink
                  button="border"
                  icon="keyboard_double_arrow_left"
                  iconPosition="start"
                  to="/news"
                >
                  Back to News
                </OTLink>
              </div>
            </section>
          </div>
        </Layout>
      </div>
    )
  }

  const { content } = storyblokData

  const components = content?.content?.map(blok => (
    <StoryblokComponent blok={blok} doc={blok} key={blok._uid} />
  ))

  return (
    <div {...storyblokEditable(content)} className="editor-only-styles">
      <Layout isEditorMode={true}>{components}</Layout>
    </div>
  )
}

export default SBEditor
